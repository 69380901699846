/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../VodafoneIdea.scss';
// import "../PriCaf/PRICafForm.css";
import SectionHeader from '../../vilBranding/SectionHeader';
// import "../ItfsCaf/itfs.css";

import {
  useLoadRomsFormFieldListData,
  useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { Spinner, Center, useTheme } from '@chakra-ui/react';
import { encode } from '../../cafCrf.util';

const BulkSMS = ({ formData, queueData }) => {
  const [cafData, setCafData] = useState(false);
  const [bulkSMSDataUpdated, setUpdatedCafData] = useState(null);
  const [companySeal, setCompanySeal] = useState(null);
  const [photoGraph, setPhotograph] = useState(null);
  const [authorizedSignatoryName, setAuthSignName] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null);
  const [photoGraphImageData, setphotoGraphImageData] = useState(null);
  const [photoGraphImageType, setphotoGraphImageType] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadCompanySeal } = useLoadCompanySeal();
  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCafData(false);
          setUpdatedCafData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 1724,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = '';
          let photoGraph = '';
          let authorizedSignatoryName = '';
          let data = [];
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted;
          } else {
            data = [];
          }
          data.forEach(function (fieldObj, index) {
            if (Number(fieldObj.field_id) === 18143) {
              companySeal = fieldObj.field_value;
            }
            if (Number(fieldObj.field_id) === 18144) {
              photoGraph = fieldObj.field_value;
            }
            if (Number(fieldObj.field_id) === 18142) {
              authorizedSignatoryName = fieldObj.field_value;
            }
          });
          setCompanySeal(companySeal);
          setPhotograph(photoGraph);
          setAuthSignName(authorizedSignatoryName);
        },
      }
    );
  };

  useEffect(() => {
    setCafData(true);
    fetchCompanySeal();
    fetchFieldListData();
  }, []);

  useEffect(() => {
    let data = bulkSMSDataUpdated;
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[17984].value) {
        // manager sign url
        let acctManagerSignUrl = data[17984].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
          setacctMngSignImageType(type);
        });
      }
      if (!!photoGraph) {
        // customer photograph url
        let photographUrl = photoGraph;
        loadImageBlob(photographUrl, (data, type) => {
          setphotoGraphImageData(data);
          setphotoGraphImageType(type);
        });
      }
    }
  }, [bulkSMSDataUpdated, companySeal, photoGraph, authorizedSignatoryName]);

  let planTariff = '';
  let vcCafContentView = '';
  let firstCaps = '';
  let secondBold = '';
  let Signcss;

  if (!!bulkSMSDataUpdated) {
    planTariff = bulkSMSDataUpdated[17936].value
      ? bulkSMSDataUpdated[17936]?.value
      : bulkSMSDataUpdated[17937]?.value;

    if (!!authorizedSignatoryName) {
      let customerSignSplit = authorizedSignatoryName.split(' ');

      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
        secondBold = customerSignSplit['1'];
        Signcss = 'signate_img input_sign';
      } else {
        firstCaps = customerSignSplit['0'];
        secondBold = '';
        Signcss = 'signate_img input_sign under_line';
      }
    }

    vcCafContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <SectionHeader name="Customer Application Form">
                  {' '}
                </SectionHeader>
                <div className="over_hid w_85">
                  <div className="">
                    <div className="textcenter">
                      Unique ID
                      <input
                        className="form_col brdr_b1 "
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>

                <div className="over_hid pad_t10">
                  <div className="fleft width75">
                    <p className="ligthbg">Name Address </p>
                    <p className="auth">
                      {' '}
                      Name of Company
                      <input
                        className="form_col width80 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17874].value}
                        readOnly
                      />
                    </p>
                    <p className="auth">
                      Comany's Registered
                      <input
                        className="form_col width80 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17875].value}
                        readOnly
                      />
                    </p>
                    <div className="auth margin0">
                      <span className="width54px">Address</span>
                      <input
                        className="form_col width85 flright"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width50">
                        Landmark
                        <input
                          className="form_col width65"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17876].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width25">
                        State
                        <input
                          className="form_col width65"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17878].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width25">
                        Pincode
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17879].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width58">
                        Landline No. +91
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17880].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width42">
                        <span className="faxn">Fax No.</span>
                        <input
                          className="form_col width52 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17881].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width50">
                        CIN Number
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17882].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        <span className="pad_t5">Company PAN Card Number</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17883].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flright cross_fill">
                    <div className="crossign" style={{ height: '100px' }}>
                      {photoGraphImageData !== null && photoGraphImageData ? (
                        <div
                          style={{
                            padding: '5px',
                            position: 'relative',
                            textAlign: 'center',
                          }}
                          className="signate_img"
                        >
                          <img
                            style={{ height: '90px' }}
                            src={
                              photoGraphImageData !== null
                                ? 'data:' +
                                  photoGraphImageType +
                                  ';base64,' +
                                  encode(photoGraphImageData)
                                : ''
                            }
                            alt={'PhotoGraph with Crosssign'}
                          />
                          {firstCaps !== '' ? (
                            <span
                              style={{
                                padding: '10px',
                                // position: 'absolute',
                                left: '-20px',
                                top: '20px',
                              }}
                              className={Signcss}
                            >
                              {firstCaps + '  .  '}
                              <span className="under_line">{secondBold}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <p className="ligthbg">Basic Organisation Details </p>
                <div className="over_hid ">
                  <div className="fleft width49">
                    <p className="margin0">
                      <strong>Address 1 (Authorised Signatory Location)</strong>
                    </p>
                    <div className="auth margin0 pad_5t">
                      <span className="width54px">Name</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17886].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0">
                      <span className="width54px">Father's/ Husbends</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17887].value}
                        readOnly
                      />
                    </div>

                    <div className="auth margin0">
                      <span className="width54px">Nationality</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17888].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0">
                      <span className="width54px">Billing Address</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17889].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0">
                      <span className="width54px">&nbsp;</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width58">
                        Landmark
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17890].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width42">
                        <span className="pad_l10">City</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17891].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width58">
                        State *
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17892].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width42">
                        <span className="pad_l10">Pincode</span>
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17893].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width50">
                        Mobile No. +91
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17894].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        <span className="faxn">Landline No. +91</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17895].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 pad_5t">
                      <span className="width54px">Email ID</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17896].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width33">
                        Designation
                        <input
                          className="form_col width30"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17897].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width33">
                        Emp.Code
                        <input
                          className="form_col width30"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17898].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width33">
                        Vehicle No.
                        <input
                          className="form_col flright width33"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17899].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <p>*Mandatory for GST registered companies</p>
                    <div className="check margin0 over_hid">
                      <div className="fleft width20">
                        GSTIN
                        {bulkSMSDataUpdated[17920].value === 'GSTIN' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20">
                        UIN
                        {bulkSMSDataUpdated[17920].value === 'UIN' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                      <div className="fleft width20">
                        GST_ISD
                        {bulkSMSDataUpdated[17920].value === 'GST_ISD' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flright width49">
                    <p className="margin0">
                      <strong>Address 2 (Key contract Location)</strong>
                    </p>
                    <div className="auth margin0 pad_5t">
                      <span className="width54px">Name</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17901].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0">
                      <span className="width54px">Address</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17902].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0">
                      <span className="width54px">&nbsp;</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width58">
                        Landmark
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17903].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width42">
                        <span className="pad_l10">City</span>
                        <input
                          className="form_col width70 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17904].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width58">
                        State *
                        <input
                          className="form_col width70"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17905].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width42">
                        <span className="pad_l10">Pincode</span>
                        <input
                          className="form_col width60 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17906].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width50">
                        Mobile No. +91
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17907].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        <span className="faxn">Landline No. +91</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17908].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 pad_5t">
                      <span className="width54px">Email ID</span>
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17909].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width33">
                        Designation
                        <input
                          className="form_col width30"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17910].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width33">
                        Emp.Code
                        <input
                          className="form_col width30"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17911].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width33">
                        Vehicle No.
                        <input
                          className="form_col flright width33"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17912].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <p className="margin0">
                      <strong>Organisation Type </strong>
                    </p>
                    <div className="check margin0 over_hid fon8_5">
                      <div className="fleft pad_r3">
                        {bulkSMSDataUpdated[17913].value === 'Partner Ship' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Partnership
                      </div>
                      <div className="fleft pad_r3">
                        {bulkSMSDataUpdated[17913].value ===
                        'Proprietorship' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Proprietorship
                      </div>
                      <div className="fleft pad_r3">
                        {bulkSMSDataUpdated[17913].value ===
                        'Private Limited' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Private Limited{' '}
                      </div>
                      <div className="fleft pad_r3">
                        {bulkSMSDataUpdated[17913].value === 'Public Sector' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Public Sector
                      </div>
                      <div className="fleft">
                        {bulkSMSDataUpdated[17913].value === 'Government' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Government
                      </div>
                    </div>
                    <div className="margin0 over_hid pad_5t">
                      <div className="fleft">
                        <span className="">
                          {bulkSMSDataUpdated[17913].value === 'Others' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                              readOnly
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                          Others
                        </span>{' '}
                        <span className="auth">
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17914].value}
                            readOnly
                          />
                        </span>{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft width12 pad_t2">
                        <strong>Telemarketer</strong>
                      </div>
                      <div className="fleft width12">
                        {bulkSMSDataUpdated[17915].value === 'Yes' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                      <div className="fleft width12">
                        {bulkSMSDataUpdated[17915].value === 'No' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                    <div className="auth margin0">
                      {' '}
                      If Yes, Telemarketer Registration Number
                      <input
                        className="form_col width40"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17916].value}
                        readOnly
                      />
                    </div>
                    <div className="check margin0 over_hid typecheck">
                      <div className="fleft pad_t2">
                        <strong>OSP &nbsp; &nbsp; </strong>
                      </div>
                      <div className="fleft width12">
                        {bulkSMSDataUpdated[17917].value === 'Yes' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Yes
                      </div>
                      <div className="fleft width12">
                        {bulkSMSDataUpdated[17917].value === 'No' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        No
                      </div>
                    </div>
                    <div className="auth margin0">
                      {' '}
                      If Yes, OSP Registration Number
                      <input
                        className="form_col width40"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17918].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="check margin0 over_hid typecheck">
                  <div className="fleft width12 pad_t2">GST Customer Type</div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value === 'Normal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Normal
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value === 'SEZ' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SEZ
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value === 'Diploma' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diploma
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value === 'Embassy' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Embassy
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value === 'Consulate' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consulate
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value === 'Special Agency' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Special Agency
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17921].value ===
                    'Person/Class of Persons' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Person/Class of Persons
                  </div>
                </div>
                <p className="auth">
                  GSTIN / UIN / GST_ISD No
                  <input
                    className="form_col wid50"
                    type="text"
                    name=""
                    value={bulkSMSDataUpdated[17922].value}
                    readOnly
                  />
                </p>
                <div className="auth">
                  <span className="width70px in_block">
                    GST Regd. Address**
                  </span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value={bulkSMSDataUpdated[17923].value}
                    readOnly
                  />
                </div>
                <div className="auth">
                  <span className="width70px in_block">State**</span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value={bulkSMSDataUpdated[17924].value}
                    readOnly
                  />
                </div>
                <p>If GST Address is diffrent from Billing Address</p>
                <div className="check over_hid typecheck gstcheck">
                  <div className="fleft width12 pad_t2">GST Customer Dept</div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'IT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'Tech' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Tech
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'Cust Service' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Cust Service
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'Legal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Legal
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'Normal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finanace
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'Purchase' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Purchase
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'SCM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SCM
                  </div>
                  <div className="fleft width12">
                    {bulkSMSDataUpdated[17926].value === 'Delivery' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Delivery
                  </div>
                  <div className="fleft width20 pad_t2">
                    Others
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17927].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="auth namsupp">
                  Name of Supporting Document
                  <input
                    className="form_col wid50"
                    type="text"
                    name=""
                    value={bulkSMSDataUpdated[17928].value}
                    readOnly
                  />
                </p>
                <p className="gsttext">
                  (GST Certificate, SEZ Certificate, Diploma Crtificate)
                </p>
                <div className="over_hid ">
                  <div className="fleft width49">
                    <p className="ligthbg">Proof of Identity Details</p>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width50">
                        Type of POI
                        <input
                          className="form_col width60"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17930].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        <span className="faxn">Document No.</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17931].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width45">
                        Date of Issue
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17932].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width55">
                        <span className="faxn">Place of Issue</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17933].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 pad_5t">
                      <span className="">Issuing Authority</span>
                      <input
                        className="form_col width70 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17934].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 pad_5t">
                      {' '}
                      Multiple Connections (Please fill enclosed declaration)
                    </div>
                  </div>
                  <div className="flright width49">
                    <p className="ligthbg">Proof of Address Details</p>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width50">
                        Type of POA
                        <input
                          className="form_col width60"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17940].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width50">
                        <span className="faxn">Document No.</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17941].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 over_hid pad_5t">
                      <div className="fleft width45">
                        Date of Issue
                        <input
                          className="form_col width50"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17942].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width55">
                        <span className="faxn">Place of Issue</span>
                        <input
                          className="form_col width45 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17943].value}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="auth margin0 pad_5t">
                      <span className="">Issuing Authority</span>
                      <input
                        className="form_col width70 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17944].value}
                        readOnly
                      />
                    </div>
                    <div className="auth margin0 pad_5t">
                      <span className="">Existing VIL Numbers (Count)</span>
                      <input
                        className="form_col width55 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17945].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="auth margin0 pad_5t over_hid">
                  <div className="fleft width40">
                    <span className="">Tariff Plan applied</span>
                    <input
                      className="form_col width65 flright"
                      type="text"
                      name=""
                      value={planTariff}
                      readOnly
                    />
                  </div>
                  <div className="fleft width60">
                    <span className="">
                      Value Added Services applied (if any)
                    </span>
                    <input
                      className="form_col width55 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17938].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">
                  Local Reference (For National Outstation and Foreign National
                  Customers)
                </p>
                <div className="auth margin0 pad_5t over_hid">
                  <div className="fleft width45">
                    <span className="">Name</span>
                    <input
                      className="form_col width85 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17947].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    <span className="">Contact No.</span>
                    <input
                      className="form_col width65 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17948].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    <span className="">Time&Date of Call</span>
                    <input
                      className="form_col width45 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17950].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 pad_5t over_hid">
                  <div className="fleft width75">
                    <span className="">Address</span>
                    <input
                      className="form_col width85 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17949].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    <span className="">
                      Calling Party's No.
                      <br />
                      <span className="fnt6">(to be filled by POS)</span>
                    </span>
                    <input
                      className="form_col width45 flright posinput"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17951].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="ligthbg">Current Requirement</p>
                <div className="over_hid ">
                  <div className="fleft width49">
                    <div className="auth margin0 pad_5t over_hid">
                      <div className="fleft width55">
                        <span className="">No. of connections required</span>
                        <input
                          className="form_col width25 flright"
                          type="text"
                          name=""
                          value={bulkSMSDataUpdated[17953].value}
                          readOnly
                        />
                      </div>
                      <div className="flright ">
                        <span className="">NDNC Regestration Opt IN</span>
                      </div>
                    </div>
                    <div className="check margin0 over_hid fon8_5">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('No commercial Call/ SMS') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        1. No commercial Call/ SMS{' '}
                      </div>
                      <div className="flright">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf(
                            'Banking/ Insurance/ Financal Produts/ Credit Card'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        2. Banking/ Insurance/ Financal Produts/ Credit Card{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid fon8_5">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('Real Estate') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        3. Real Estate{' '}
                      </div>
                      <div className="fleft pad_l10">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('Education') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        4. Education{' '}
                      </div>
                      <div className="fleft pad_l10">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('Health') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        5. Health{' '}
                      </div>
                      <div className="flright">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('Consumer goods and Automoblies') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        6. Consumer goods and Automoblies{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid fon8_5">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf(
                            'Communication/ Broadcasting/ Entertainment/ IT'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        7. Communication/ Broadcasting/ Entertainment/ IT{' '}
                      </div>
                      <div className="fleft pad_l10">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('Tourism and Leisure') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        8. Tourism and Leisure{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid fon8_5">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17954].value
                          .split('|')
                          .indexOf('Any other category') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        9. Any other category{' '}
                      </div>
                    </div>
                    <p className="margin0">Type of Service</p>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value === 'Mobile Voice' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Mobile Voice{' '}
                      </div>
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value ===
                        'Mobile Broadband 2G 3G' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Mobile Broadbond 2G/3G/4G{' '}
                      </div>
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value ===
                        'Wireline Voice (E1 DID)' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Wireline Voice (E1 DID){' '}
                      </div>
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value ===
                        'Domestic SIP Trunk' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Domestic SIP Trunk{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value === 'SIP Central' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        SIP Central{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value ===
                        'Domestic Toll Free' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Domestic Toll Free{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value ===
                        'International Toll Free' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        International Toll Free{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value ===
                        'Audio Conferencing' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Audio Conferencing{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value ===
                        'Video Conferencing ' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Video Conferencing{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value ===
                        '2G/3G/4G API Solution' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        2G/3G/4G API Solution{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value === 'GVN' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        GVN{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value ===
                        'Cloud Telephony' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Cloud Telephony{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17955].value ===
                        'Call Recording' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Call Recording{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value ===
                        'Mobile Applications' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Mobile Applications{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17955].value === 'Others' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others
                        <span className="in_subput_date">
                          <input
                            className="form_col flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17956].value}
                            readOnly
                          />
                        </span>
                      </div>
                    </div>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft pad_t3">
                        {' '}
                        <strong>Internet of Things</strong>{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17957].value === 'Connectivity' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Connectivity{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17957].value ===
                        'Managed Connectivity' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Managed Connectivity{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17957].value === 'SuperIOT' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Integrated IOT{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17957].value ===
                        'Location Based Services' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Location tracking Solutions{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17957].value ===
                        'Logistics Tracking' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Logistics Tracking{' '}
                      </div>
                      <div className="fleft pad_l_2 ">
                        {bulkSMSDataUpdated[17957].value === 'Others' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others{' '}
                        <span className="in_subput">
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17958].value}
                            readOnly
                          />
                        </span>{' '}
                      </div>
                    </div>
                    <p className="auth">
                      Tariff Plan
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={planTariff}
                        readOnly
                      />
                    </p>
                  </div>
                  <div className="flright width49">
                    <p className="margin0">Services on Voice</p>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17959].value === 'NCUG' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        NCUG{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17959].value === 'IR' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        IR{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17959].value === 'Itemised Bill' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Itemised Bill{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17959].value === 'GPRS' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        GPRS{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17959].value === 'ISD' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        ISD{' '}
                      </div>
                      <div className="fleft pad_l_2 ">
                        {bulkSMSDataUpdated[17959].value === 'Others' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others{' '}
                        <span className="in_subput">
                          <input
                            className="form_col width60 flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17960].value}
                            readOnly
                          />
                        </span>{' '}
                      </div>
                    </div>
                    <br />
                    <p className="margin0">Value Added Services:</p>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17961].value === 'CCT' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        CCT{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17961].value === 'MCI' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        MCI{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17961].value ===
                        'Enterprise Alerts' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Enterprise Alerts{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17961].value === 'VMC' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        VMC{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17961].value ===
                        'Mobile Internet 2G/3G/4G' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Mobile Internet 2G/3G/4G{' '}
                      </div>
                    </div>
                    <br />
                    <div className="check margin0 over_hid">
                      <div className="">
                        {bulkSMSDataUpdated[17961].value === 'Others' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others{' '}
                        <span className="in_subput">
                          <input
                            className="form_col wid83p flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17962].value}
                            readOnly
                          />
                        </span>{' '}
                      </div>
                    </div>
                    <br />
                    <p className="margin0">Devices</p>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17963].value === 'BlackBerry' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Blackberry{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17963].value === 'Smartphone' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Smartphone{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17963].value === 'Tablet' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Tablet{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17963].value === 'Netbook' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Netbook{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17963].value === 'USB Stick' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        USB Stick{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17963].value === 'Moible Wifi' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Moible Wifi{' '}
                      </div>
                    </div>
                    <div className="check margin0 over_hid font_8">
                      <div className="fleft">
                        {bulkSMSDataUpdated[17963].value === 'Sharing Dock' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Sharing Dock{' '}
                      </div>
                      <div className="fleft pad_l_2">
                        {bulkSMSDataUpdated[17963].value === 'GSM Gateway' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        GSM Gateway{' '}
                      </div>
                    </div>
                    <br />
                    <div className="check margin0 over_hid">
                      <div className="">
                        {bulkSMSDataUpdated[17963].value === 'Others' ? (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                        Others{' '}
                        <span className="in_subput">
                          <input
                            className="form_col wid83p flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17964].value}
                            readOnly
                          />
                        </span>{' '}
                      </div>
                    </div>
                    <br />
                    <div className="check margin0 over_hid">
                      <div className="">
                        {' '}
                        CUG Code{' '}
                        <span className="in_subput">
                          <input
                            className="form_col wid83p flright"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17968].value}
                            readOnly
                          />
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth over_hid custype pad_t5">
                  <div className="fleft width20 pad_7t">
                    E-Bill Opt In (Go Green)
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col fleft width30"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17966].value}
                      readOnly
                    />
                  </div>
                  <div className="flright width50">
                    {' '}
                    E-Mail ID for sending bills
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17967].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">Payment Details</p>
                <div className="auth margin0 pad_5t over_hid">
                  <div className="fleft width70">
                    <span className="">
                      Name of Person Responsiable for Payments
                    </span>
                    <input
                      className="form_col width55 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17970].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    <span className="">Mobile No. +91</span>
                    <input
                      className="form_col width60 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17971].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check margin0 over_hid font_8">
                  <div className="fleft width30"> Billing Address</div>
                  <div className="fleft pad_l_5">
                    {bulkSMSDataUpdated[17972].value ===
                    'Authorised Sign Location' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Authorised Sign Location
                  </div>
                  <div className="fleft pad_l_5">
                    {bulkSMSDataUpdated[17972].value ===
                    'Key Contact Location' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Key Contact Location
                  </div>
                  <div className="fleft ">
                    {bulkSMSDataUpdated[17972].value ===
                    'Company Registered Address' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Company Registered Address
                  </div>
                </div>
                <div className="check margin0 over_hid font_8">
                  <div className="fleft pad_l_5 width30"> Payment Mode</div>
                  <div className="fleft pad_l_5">
                    {bulkSMSDataUpdated[19149].value === 'ECS' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ECS
                  </div>
                  <div className="fleft pad_l_5">
                    {bulkSMSDataUpdated[19149].value === 'SI' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SI
                  </div>
                  <div className="fleft pad_l_5">
                    {bulkSMSDataUpdated[19149].value === 'CHEQUE' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    CHEQUE
                  </div>
                  <div className="fleft pad_l_5 pad_5t">
                    {' '}
                    Check (for ECS and SI, please fill adational form){' '}
                  </div>
                </div>
                <div className="check margin0 over_hid">
                  <div className="fleft">
                    {' '}
                    Deposit Amount{' '}
                    <span className="in_subput">
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17973].value}
                        readOnly
                      />
                    </span>{' '}
                  </div>
                  <div className="fleft pad_l10">
                    {' '}
                    Deposit Type
                    {bulkSMSDataUpdated[17974].value === 'Deposit' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft pad_l_2">
                    {' '}
                    Security
                    {bulkSMSDataUpdated[17974].value === 'Security' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft pad_l_2">
                    {' '}
                    IR Deposit
                    {bulkSMSDataUpdated[17974].value === 'IR Deposit' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft pad_l_2 ">
                    {' '}
                    Others{' '}
                    <span className="in_subput">
                      <input
                        className="form_col width60 flright"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17975].value}
                        readOnly
                      />
                    </span>{' '}
                  </div>
                </div>
                <p className="ligthbg">Customer Declaration</p>
                <div className="check margin0 over_hid">
                  <div className="fleft width70">
                    <div className="pad_r15_pri">
                      I/We agree to refundable security deposit and all charges
                      associated with the services selected by me/us. I/We have
                      read and terms and conditions mentioned overleaf and
                      unconditionally accept them as binding on me/us. I/'We
                      have understood all the rates. tariffs and other related
                      co it telecommunications services be provided inside and
                      outside India as applicable as on this date and as amended
                      from time to time. I/We hereby undertake to pay all
                      charges raised on account Of Services availed, l/We
                      undertake that in the event this connection is used for
                      any telemarketing purpose. I/We shall be solely
                      responsible for registration of the connection with the
                      mandated statutory authorities. further declare and
                      undertake that above information provided by me/us is true
                      and correct in all respect. I/We understand that the SIM
                      non-transferable. Any misuse of SIM by customer or any
                      other person is illegal and shall be liable for criminal
                      actions.
                    </div>
                    <div className="check margin0 over_hid pad_t10">
                      <div className="">
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                        I agree to receive a welcome mail giving details of
                        service(s) subscribed{' '}
                      </div>
                    </div>
                  </div>
                  <div className="fleft width30 ">
                    <div className="signate_box">
                      <table>
                        <tbody>
                          <tr>
                            <td width="50%">
                              {firstCaps !== '' ? (
                                <div
                                  style={{
                                    padding: '10px',
                                    position: 'absolute',
                                  }}
                                  className={Signcss}
                                >
                                  {firstCaps + '  .  '}
                                  <span className="under_line">
                                    {secondBold}
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                            <td width="50%">
                              {imageData !== null && imageData !== '' ? (
                                <div
                                  style={{ padding: '0px' }}
                                  className="signate_img"
                                >
                                  <img
                                    src={
                                      imageData !== null
                                        ? 'data:' +
                                          imageType +
                                          ';base64,' +
                                          encode(imageData)
                                        : ''
                                    }
                                    alt={'Company Seal'}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Sign &amp; Seal</td>
                            <td>
                              <spa className=" pad_l_2 ">
                                {' '}
                                Date{' '}
                                <span className="in_subput_date">
                                  <input
                                    className="form_col flright"
                                    type="text"
                                    name=""
                                    value=""
                                  />
                                </span>{' '}
                              </spa>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <p className="ligthbg">VIL Internet Use</p>
                <div className="auth margin0 pad_5t over_hid">
                  <div className="fleft width60">
                    <span className="">Enterprise Description Code</span>
                    <input
                      className="form_col width65 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17977].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width40">
                    <span className="">Large Account Code</span>
                    <input
                      className="form_col width60 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17978].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0 pad_5t over_hid">
                  <div className="fleft width60">
                    <span className="">FA/BA/PCN ID</span>
                    <input
                      className="form_col width65 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17979].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check margin0 over_hid">
                  <div className="fleft width49">
                    <div className="check margin0 over_hid">
                      <div className="fleft width65 pad_r15_pri">
                        {' '}
                        Verification by Dealer/Agent (Distributor/ Retailer): I
                        confirm that the applicant has signed the form and
                        documents attached in my presence and that the photo
                        affixed in this form is of the applicant. I have
                        verified the documents With their respective originals
                        and certify the same to be true. I further confirm that
                        all necessary documents are in order including that the
                        signatures on the self attested photo match with the
                        signature on application form and Photo on the form
                        matches the one on the photo ID document.{' '}
                      </div>
                      <div className="flright width34">
                        {acctMngSignImageData !== null &&
                        acctMngSignImageData !== '' ? (
                          <img
                            height="75px"
                            width="75px"
                            src={
                              acctMngSignImageData !== null &&
                              acctMngSignImageData !== ''
                                ? 'data:' +
                                  acctMngSignImageType +
                                  ';base64,' +
                                  encode(acctMngSignImageData)
                                : ''
                            }
                            alt={'Sign'}
                          />
                        ) : (
                          ''
                        )}
                        <div>Sign in with Seal/Stamp &nbsp; &nbsp; </div>
                        <div className="in_subput_date">
                          Date
                          <input
                            className="form_col"
                            type="text"
                            name=""
                            value={bulkSMSDataUpdated[17985].value}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flright width49">
                    <p className="ligthbg">
                      Declaration of VIL employee activating SIM
                    </p>
                    <div className="check margin0 over_hid">
                      <div className="fleft width55 pad_r15_pri">
                        {' '}
                        I hereby confirm that the required documentation Such as
                        POI/POA is available and that details of the Customer is
                        complete and have been entered in the database.{' '}
                      </div>
                      <div className="flright width45">
                        <div className="activatesim">
                          <div className="check margin0 over_hid">
                            <div className="flright width70">
                              {acctMngSignImageData !== null &&
                              acctMngSignImageData !== '' ? (
                                <img
                                  height="25px"
                                  width="75px"
                                  src={
                                    acctMngSignImageData !== null &&
                                    acctMngSignImageData !== ''
                                      ? 'data:' +
                                        acctMngSignImageType +
                                        ';base64,' +
                                        encode(acctMngSignImageData)
                                      : ''
                                  }
                                  alt={'Sign'}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="flright width70">
                              <div className="font7">
                                <div className="in_subput">Employee Name</div>
                              </div>
                              <div className="font7 in_subput">
                                <input
                                  className="form_col width100per"
                                  type="text"
                                  name=""
                                  value={bulkSMSDataUpdated[17997].value}
                                  readOnly
                                />
                              </div>
                              <div className="font7">
                                <div className="in_subput">
                                  Designation
                                  <input
                                    className="form_col width40px"
                                    type="text"
                                    name=""
                                    value=""
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="font7">
                                <div className="in_subput_date">
                                  Date
                                  <input
                                    className="form_col"
                                    type="text"
                                    name=""
                                    value={bulkSMSDataUpdated[17985].value}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="margin0">
                  <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                  Cellular Limited )<br />
                  An Adity Birla Group and Vodafone partneship
                  <br />
                  MergerCo CIN No: L32100GJ1996PLC030976
                  <br />
                  <strong>Registred Office:</strong> Suman Tower, Plot no. 18,
                  Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000 |
                  F: +91 79 23232251
                  <br />
                  <strong>Circle Office Address:</strong>{' '}
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="ligthbg font8">TERMS &amp; CONDITIONS </p>
                <p className="font10">
                  <strong>
                    The "Customer Application Form" (CAF) shall mean the
                    Customer information form. to which the following conditions
                    ("Regulatory Terms") are required to be provided/completed
                    with effect from /prior to activation of any Services, and
                    continue during the term of service. These are conditions
                    specified by the Department of Telecommunication ("DOT)
                    Ministry of Communication and Information Technology
                    ("MCIT"), Government of India ("Go") and are mandatory
                    conditions. There shall be no deletion or deviation from the
                    following Regulatory Terms. This CAF shall be read with the
                    Product Specific Terms which shall form the Agreement
                    between the Parties. Licensed Service Area • shall mean the
                    area where Operator is permitted to offer telecommunication
                    services, (i) Operator • shall mean VIL entity having
                    license to operate in Circle. o 'Authority' shall mean the
                    Department of Telecommunications (DOT). Telecom Regulatory
                    Authority of India (TRAI), Ministry of Communication and
                    Information Technology (MOCIT), Government of India (GOI)
                    and includes any officer of the Authority,' "Corporate Plan"
                    shall mean tariff structure devised by for Corporate
                    Customers. • 'Charges' means all fees, charges/ tariffs,
                    interconnection cost and rates chargeable by VIL from time
                    to time for provisioning the Services to the Customer •
                    'Customer' shall mean Corporate organization named in the
                    CAF entering into the requisite contract comprising of CAF,
                    Verification documents etc, For Internet of Things products,
                    refer Intemet Of Things GTCs agreed by the customer.{' '}
                  </strong>{' '}
                </p>
                <div className="over_hid">
                  <div className="fleft width49">
                    <ul className="list">
                      <li>
                        It is agreed betweet "Vodafone Idea Limited (VIL)" &
                        "Customer" as fallows;
                      </li>
                      <li>
                        1. PROVISION SERVICES: VIL agrees to provide the
                        services to the Customer subject to terms and condition
                        of the Agreement.{' '}
                      </li>
                      <li style={{ display: 'inline' }}>
                        2. OBLIGATIONS OF VIL: VIL shall provide services with
                        reference to and subject to the telecommunication
                        permitting the operations of a telephony services. The
                        Customer expressly agrees that activation of the
                        Services shall be Subject to verification of the
                        particulars and documents submitted by the Customer
                        including tele-verification. The availability and
                        quality of services may be affected by factor outside
                        VIL control such as physical obstruction. geographic and
                        weather conditions and other cause of radio interference
                        or faults in other telecommunications network to which
                        network is connected. The Services may be Suspended in
                        Whole or in part at any time with proper notice and in
                        compliance to existing guidelines. policies and
                        regulations.. however if the network fails or requires
                        modifications or maintenance due to a sudden or force
                        majeure event beyond control of operator. such advance
                        notice may not be possible. Please note all extra
                        discounting promos on special deals or on Corporate
                        Plans and Closed User Group (COG). Vi<sup>TM</sup>{' '}
                        Mobile Connect . Vi
                        <sup>TM</sup> Live . Blackberry (BB) and other Data
                        services along with respective discounts on usage will
                        be activated minimum of 72hrs from date of number
                        activation or the request as the case may be, The
                        allotment of a number will be made by VIL in its
                        absolute discretion. In the event of any provisioned SIM
                        ( where a Service involves a provisioning of SIM) being
                        lost or stolen. VIL will replace the SIM card as soon as
                        it is reasonable & practicable. subject to the recovery
                        of any charges for the same. VIL reserve the right to
                        apply a monthly financial limit and such other
                        conditions for charges incurred by the Customer and to
                        demand interim advance payment and also suspend (and/or
                        disconnect access to the services if such limit is
                        exceeded with proper intimation and in compliance to
                        existing guidelines, policies and regulations VIL has
                        the right to check the credential Of the Customer
                        including the Customer financial standing & to use the
                        services of any person or agency for such purposes. Any
                        waiver. concession or extra time allowed or granted by
                        VIL to the Customer is limited the specific
                        circumstances in which it was given and the same shall
                        not effect VIL's right under this agreement in any ways.
                      </li>
                      <li>
                        3. OBLIGATIONS OF THE CUSTOMER: The Customer shall
                        ensure (i) that it signs all bulk connection
                        declarations and forms as are created pursuant to DOT
                        guidelines on bulk connections; (ii) that it has the
                        users comply with the said regulations and conclude the
                        tele-verification process by calling the call centre at
                        1 17 so as to enable VIL to activate his/her subscribed
                        service plan: (iii) thet it shall not use or cause or
                        allow others to use the Services for any improper.
                        immoral or unlawful purpose including in any manner
                        which may jeopardise or impair the operation Of the
                        Network and/or the Services. Customer agrees to inform
                        VIL immediately and confirm the same in writing if the
                        Equipment and/or the SIM Card is lost. stolen or
                        damaged. The Customer will remain liable for all Charges
                        incurred until the SIM Card is de-activated by VIC (iv)
                        to furnish correct and complete information and
                        documents as required by VIL from time to time. The
                        Services agreed to be provided by VIL shall always be
                        subject to verification Of the Customer'S credentials
                        and documents and if at any time, any information and/or
                        documents furnished by the Customer Mare found incorrect
                        or incomplete or suspicious. VIL shall be entitled to
                        suspend/terminate the Service forthwith without any
                        further notice. In case any change of address of the
                        Customer or end user. the Customer agrees to inform VIL
                        in writing with regard to change in address and furnish
                        documents in support Of such new address. In case during
                        any time during the subscription, in case the Customer
                        does not inform with regard to change in his address or
                        end user then VIL reserves its right to disconnect the
                        Services and/or cancel the connection without any notice
                        in this regard and VIL shall not be liable to the
                        Customer/end user in any manner whatsoever.{' '}
                      </li>
                      <li>
                        4. DOT LICENSING AND VERIFICATION OF SUBSCRIBE: (i) VIL
                        may be requited to disclose any information pertaining
                        to the Customer/Subscriber to any statutory / regulatory
                        / security authority, financial institution or bank and
                        as per regulation. VIL can do so without any prior
                        intimation to the Customer: (ii) Customer shall
                        indemnify and keep indemnified VIL for all and any
                        claims. proceedings or actions brought against VIL
                        arising out of any breach of the Customer of the
                        Regulatory Terms: (iii) VIL may use. process and/or
                        transfer Personal Information: (iv) in connection with
                        the provision Of Services; (v) to incorporate Personal
                        Information into databases controlled by VIL for the
                        purpose of account administration, billing and
                        reconciliation. operational maintenance and support
                        activities. fraud detection and prevention. and customer
                        and market analysis and reporting. (vi) The Customer
                        shall use the Services only for the purposes Specified
                        by the Customer in the Agreement / Customer Application
                        Form (or Customer Order Form or Customer Information
                        Form hereinafter CAF/COF/CIF) and shall not be used for
                        or connected to any telecommunication service unless
                        otherwise permitted by the Governmental Authority with
                        specific approvals/ licenses. The Customer understands
                        and agrees that VIL shall be entitled to terminate the
                        Service and collect financial penalty as levied by the
                        Governmental Authority for any unauthorized use. VIL
                        shall also. at its sole discretion, be entitled to
                        disconnect the unauthorized circuit with immediate
                        effect. The Services shall not be used for call center/
                        telemarketing/ other service provider (OSP) operations
                        without registration With the Department of
                        Telecommunications and/ or Telecom Regulatory Authority
                        of India as a call center/ OSP. The Customer who wants
                        to take the services for call center/ OSP operations
                        shall submit copy of call center/ OSP license or
                        registration to VIL (vii)The Customer shall be allotted
                        a phone number/iden- tity number by VIL which is and
                        shall always remain the property of VIL. The Customer
                        cannot transfer market, re•brand. resell and/or
                        otherwise assign Services purchased including the phone
                        number/iden tity number. Services to any Other
                        person/entity unless agreed by VIL in writing. (viii)
                        The Customer undertakes to comply with the applicable
                        laws and regulations regarding the use Of the Service.
                        including without limitation. the TRAI Act 1997. the
                        Indian Telegraph Act 1885. the Indian Wireless
                      </li>
                    </ul>
                  </div>
                  <div className="fright width49">
                    <ul className="list">
                      <li>
                        Telegraphy Act 1933. Information Technology Act 2000 and
                        all amendments, replacements, rules and regulations made
                        under such legislations from time to time. The Customer
                        shall obtain any relevant consents and approvals for the
                        installation and use of any equipment located at the
                        Customer sites and associated with the Service and any
                        permits, consents or approvals required by the
                        Subscriber to use the Service. including any applicable
                        other service provider permits ("Permits"). Customer
                        shall use the Services in accordance with the Permits.
                        Customer shall provide VIL with an up-to-date copy of
                        the Customefs CIJG approval (if any) issued by the
                        Department Of Telecommunications from time-to-time.
                        Customer shall ensure that any network equipment (not
                        provided by Services Provider) which it uses in relation
                        to the Services meet the relevant International
                        Telecommunication Union (ITU)/Telecommunication
                        Engineering Centre (TEC) standarc'fr (ix) The Customer
                        shall ensure that unauthorized or any Other Content.
                        messages or communica- tians infringing copynght.
                        Intellectual property right and international & domestic
                        cyber laws. in any form or inconsistent with the laws of
                        India. are not carried in his network by him or any
                        other person using his network or dunng the provisioning
                        of Semces. The Customer must take all necessary measures
                        to prevent such use, The use of the Service for
                        anti-national activities shall be construed as an
                        offence punishable under the Indian Penal Code or other
                        applicable law. The Customer must ensure that any
                        service provided by Service Provider is not used for any
                        unlawful purposes or any Other person using the Services
                        as End User. G) Customer agrees and acknowledges that
                        regulatory and law enforcement agencies Law Enforcement
                        Agency (LEA), shatt have rights to access the network
                        and intercept or listen or record calls being made using
                        the Services and facilities provided by Service Provider
                        and Customer shall provide necessary
                        facilities/access/consent to Service Provider and Law
                        Enforcement Agency (LEA), including but not limited to T
                        RAJ. DOT. Police authorities. Reserve Bank of India.
                        Enforcement Directorate and Or any other Government
                        Agencies to monitor. contiol. prevent counteract
                        espionage. subversive act sabotage or any other
                        unlavvfulactivity. (xi) Customer understands Customer
                        and its End Users use the Services at their own risk and
                        consequences and Service Provider has no control over
                        and accepts no responsibility whatsoever for such the
                        Customer use of the Services and the transacted
                        business/transmitted communication and Customer shall
                        comply with all regulatory/legal and Statutory
                        requirements during such use of the Services. (xii)
                        Customer understands and agrees that Service Provider
                        shall terminate the Service provided to the Customer
                        immediately without any prior notice. upon any violation
                        of these terms and conditions by the Customer and/or On
                        account Of any regulatory violation by Customer or its
                        End Users in using the Service or in case of any
                        directive of any Law Enforcement Agency (LEA),
                        Department Of Telecommunication ("DOT"), Judicial
                        authority or any other Government agency. (xiii) The
                        customer is required to maintain and provide updated
                        list Of actual users to VIL at ad times and the customer
                        agrees to give VIL the unhindered right to visit the
                        customer premises and verify bonafide use of the
                        servrces , (o) Customer shall not use the Services for
                        any abusive, improper. immoral. offensive. illegal or
                        fraudulent purposes including making calls or sending
                        data that in VILS reasonable opinion is a nuisance.
                        hoax, menacing, indecent. racist. obscene and/ Or
                        defamatory or do anything that causes the Network to be
                        impaired or damaged. Using Services to access the
                        Internet: In the event that Customer or End User uses
                        Services to access facilities and services not included
                        in a Tariff, VIL shall not be liable under this
                        Agreement for the security or confidentiality of such
                        facilities or services. (xiv) No information with regard
                        to an account information can be shared with any third
                        party other than the duly notified authorized signatory
                        of the Customer as notified by Customer to VIL and VIL
                        cannot provide any account related information Outside
                        India.{' '}
                      </li>
                      <li>
                        5. CONTRACT TERMS AND COMMERCIAL TERMS: This CAF, shall
                        be read With the General Terms and Conditions except
                        where any master services agreement (MSA) is entered
                        including (or any India specific in-country agreement
                        (ICC) between the Customer and Service Provider entered
                        subsequent to or pursuant to any globat level MSA
                        entered between the global principals/affiliates Of the
                        Customer and Service Provider) and the MSA provisions
                        shall prevail over the GTC). Any Specific terms agreed
                        for the mobility shall be set Out in the Part C Or a
                        Product Terms and Conditions and Schedules to the GTC.
                        The CAF terms will prevail over any provisions in the
                        GTC/ or the MSA/ICC. which conflict With terms in CAF.
                        All types of Voice. SMS and Data offerings are a part of
                        the licensed services. The usage charges for these
                        services would be governed by the Tariff plan opted by
                        the customer.
                      </li>
                      <li>
                        6. GOVERNING LAW: This CAF shall be subject to Indian
                        Law and courts Of jurisdiction shall include Courts in
                        the place where Customer has a place of business or
                        where the cause of action arises or the place where the
                        Services have been offered.{' '}
                      </li>
                      <li>
                        7. MISCELLANEOUS: The Authorised Signatory signing this
                        CAF on behalf of the customer hereby confirm, consent
                        and authorize VIL to sell Vodafone Reload Plan or such
                        other plans as may be branded by VIL for their COCP
                        users. Such Plan/s will be activated upon the end user's
                        request and their advance payments made directly by the
                        end user either through VILS dedicated channels or
                        including but not limited to Vodafone website, app.
                        etc.. with no liability on the enterprise customer
                        entity. Subscriber hereby declare and gives their
                        consent to VIL for the collection, processing and use Of
                        their personal data such as. including but not limited
                        to. their name. contact details and employment related
                        information for the purposes of ustng the Services and
                        additional services according to VIL's Privacy Policy
                        displayed on its website www.myvi.in.
                      </li>
                      <li>
                        8. GST &amp; SEZ STATUS: The customer will be solely
                        responsible for providing the correct GST number and
                        'SEZ" related information against the "Place Of Supply"
                        as per the GST law. S/he waives Off all the rights to
                        dispute or withhold any payments in case of incorrect
                        GST Numbers or incorrect SEZ certificates/ status being
                        provided. which donet match the details available on the
                        Government GSTN portal. The customer acknowledges that
                        s/he will not be able to claim the GST tax credit under
                        such circumstances and shall pay 100% invoiced amount.
                        including taxes.
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="ligthbg font8">Customer Acceptance </p>
                <div className="margin0 over_hid ">
                  <div className="fleft width70">
                    <p className="auth pad_t10">
                      Customer Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17981].value}
                        readOnly
                      />
                    </p>
                    <p className="auth pad_t10">
                      Designation
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17982].value}
                        readOnly
                      />
                    </p>
                    <p className="auth pad_t10">
                      Company Name
                      <input
                        className="form_col width80"
                        type="text"
                        name=""
                        value={bulkSMSDataUpdated[17983].value}
                        readOnly
                      />
                    </p>
                    <p>
                      <strong>
                        Note: I/We accept the responsiblates of providing the
                        list of mobile nos/DID nos with user name &nbsp;
                        Designation, alongwith CFA
                      </strong>
                    </p>
                  </div>
                  <div className="fleft width30 ">
                    <div className="signate_box">
                      <table>
                        <tbody>
                          <tr>
                            <td width="50%">
                              {firstCaps !== '' ? (
                                <div
                                  style={{
                                    padding: '10px',
                                    position: 'absolute',
                                  }}
                                  className={Signcss}
                                >
                                  {firstCaps + '  .  '}
                                  <span className="under_line">
                                    {secondBold}
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                            <td width="50%">
                              {imageData !== null && imageData !== '' ? (
                                <div
                                  style={{ padding: '0px' }}
                                  className="signate_img"
                                >
                                  <img
                                    src={
                                      imageData !== null
                                        ? 'data:' +
                                          imageType +
                                          ';base64,' +
                                          encode(imageData)
                                        : ''
                                    }
                                    alt={'Company Seal'}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Sign &amp; Seal</td>
                            <td>
                              <spa className=" pad_l_2 ">
                                {' '}
                                Date{' '}
                                <span className="in_subput_date">
                                  <input
                                    className="form_col flright"
                                    type="text"
                                    name=""
                                    value=""
                                    readOnly
                                  />
                                </span>{' '}
                              </spa>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <p className="ligthbg font8">
                  {' '}
                  Fulfilment and channel details{' '}
                </p>
                <div className="auth over_hid custype pad_t5">
                  <div className="fleft width20 pad_7t">
                    Documents Verified by
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col fleft width90"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17987].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Date
                    <input
                      className="form_col width70"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17988].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid custype pad_t5">
                  <div className="fleft width20 pad_7t">Channel Partner</div>
                  <div className="fleft width30">
                    <input
                      className="form_col fleft width90"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17989].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Code
                    <input
                      className="form_col width70"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17990].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid custype pad_t5">
                  <div className="fleft width20 pad_7t">
                    Vi<sup>TM</sup> Store
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col fleft width90"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17991].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width20">
                    Code
                    <input
                      className="form_col width70"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17992].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid custype pad_t5">
                  <div className="fleft width20 pad_7t">
                    Vi<sup>TM</sup> No.
                  </div>
                  <div className="fleft width30">
                    <input
                      className="form_col fleft width90"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17993].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width40">
                    Sim Card No.
                    <input
                      className="form_col width70"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17994].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check margin0 over_hid">
                  <div className="fleft width70">
                    <div className="pad_r15 pad_t10">
                      I/We hereby undertake and confirm that I/We have seen the
                      customer and certify that (a) the customer enrolment form
                      has been duly filled by the applicant, (b) the photograph
                      submitted has been matched with the applicant, (c )the
                      form has been personally signed by the applicant in my
                      presence, (d) proof of identity & address has been
                      collected, (e) the original proof of identity & address
                      has been matched and verified with the self-attested
                      documents submitted by the applicant. Details on the Vi{' '}
                      <sup>TM</sup> CAF match with these documents. In case of
                      outstation customer, I/ We hereby confirm that I/We
                      tele-verified the particulars furnished by the applicant
                      with local reference and recorded its correctness. Name of
                      Local Reference contacted ( for Outstation subscribers).
                      <span className="in_subput">
                        <input
                          className="form_col width150px"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </span>{' '}
                    </div>
                  </div>
                  <div className="fleft width30 ">
                    <div className="signate_box">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {acctMngSignImageData !== null &&
                              acctMngSignImageData !== '' ? (
                                <div
                                  style={{ padding: '0px' }}
                                  className="signate_img"
                                >
                                  <img
                                    height="25px"
                                    width="100px"
                                    src={
                                      acctMngSignImageData !== null ||
                                      acctMngSignImageData !== ''
                                        ? 'data:' +
                                          acctMngSignImageType +
                                          ';base64,' +
                                          encode(acctMngSignImageData)
                                        : ''
                                    }
                                    alt={'Signature of Account Manager'}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Sign &amp; Seal</td>
                            <td>
                              <spa className=" pad_l_2 ">
                                {' '}
                                Date{' '}
                                <span className="in_subput_date">
                                  <input
                                    className="form_col flright"
                                    type="text"
                                    name=""
                                    value={bulkSMSDataUpdated[17985].value}
                                    readOnly
                                  />
                                </span>{' '}
                              </spa>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid pad_5t">
                  <div className="fleft width40">
                    Enterprise Code
                    <input
                      className="form_col width65"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17996].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width35">
                    Account Manager Name
                    <input
                      className="form_col width45"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17997].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Code
                    <input
                      className="form_col width70 flright"
                      type="text"
                      name=""
                      value={bulkSMSDataUpdated[17998].value}
                      readOnly
                    />
                  </div>
                </div>
                <br />
                <br />
                <br />
              </div>
            </div>
          </page>
        </div>
      </div>
    );
  } else {
    vcCafContentView = cafData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        CAF Form not submitted yet.
      </div>
    );
  }
  return <div>{vcCafContentView}</div>;
};

export default BulkSMS;
